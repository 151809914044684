@import 'prismjs/themes/prism-coy.css';

form {
    >div {
        display: inline-block;
        margin: 16px 8px 8px 8px;
        vertical-align: top;

        &.w1 {
            width: calc(8.3% - 16px);
        }

        &.w2 {
            width: calc(16.6% - 16px);
        }

        &.w3 {
            width: calc(25% - 16px);
        }

        &.w4 {
            width: calc(33.3% - 16px);
        }

        &.w5 {
            width: calc(41.6% - 16px);
        }

        &.w6 {
            width: calc(50% - 16px);
        }

        &.w7 {
            width: calc(58.3% - 16px);
        }

        &.w8 {
            width: calc(66.6% - 16px);
        }

        &.w9 {
            width: calc(75% - 16px);
        }

        &.w10 {
            width: calc(83.3% - 16px);
        }

        &.w11 {
            width: calc(91.6% - 16px);
        }

        &.w12 {
            width: calc(100% - 16px);
        }

        >span> :not(label) {
            width: 100%;
        }
    }
}

.master-form {
    >div {
        margin-top: 24px
    }

    small {
        display: inline-block;
        width: 100%;
    }
}

i.boolean {
    font-size: 2rem;

    &.pi.pi-circle {
        color: #080;
    }

    &.pi.pi-times {
        color: #B23B3B;
    }
}

.orphan-header {
    display: inline-block;
    width: 100%;

    button.new-btn {
        width: auto;
        padding-left: 6px;
        padding-right: 6px;
        float: right;
        background-color: #00CC95;
        color: #F3F3F3;
        font-weight: bold;

        span {
            margin-right: 4px;
        }
    }
}

.acaa-form {
    label {
        display: inline-block;
        width: 100%;
    }

    label.checkbox {
        width: auto;
    }

    >div {
        margin-top: 24px;
        display: inline-block;
        width: 50%;
        vertical-align: top;

        >span>div {
            min-width: 75%;
        }
    }

    >div.full {
        margin-top: 24px;
        display: inline-block;
        width: 100%;
    }

    >div.full.with-button {
        >span {
            display: inline-block;
            width: 75%;

            >div {
                width: 100%;
            }
        }

        >button {
            display: inline-block;
            margin-left: 8px;
        }
    }

    small {
        width: 100%;
        display: inline-block;
    }
}

.acaa.tooltip {
    background-color: #FFFFFF;
    color: #000;
    padding: 0;
    border-color: #FFF;
}

div.submit-footer {
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #999999;

    button {
        width: 50%;
        margin-left: 25%;
        margin-top: 12px;
    }
}

.master-crud-delete {
    .delete-entry {
        margin: 16px 16px 0 16px;
        padding: 8px 0;
        text-align: center;
        font-size: 2rem;
        background-color: #EEEEEE;
    }

    .cancel-footer {
        margin-top: 30px;
        border-top: 1px solid #999999;
        width: 100%;

        button {
            width: 48%;
            margin: 12px 1% 0 1%;
        }

        :nth-child(2) {
            background-color: #997570;
        }
    }
}

#Map {
    position: relative;
    width: 100%;
    height: 100%;
}

#MapBase {
    position: absolute;
    top: 70px;
    margin-left: 8px;
    padding: 0;

    .p-card-body {
        padding: 0;

        .p-card-content {
            padding: 4px 8px;
        }
    }

    .switch-text {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .p-inputswitch {
        vertical-align: top;
        margin: 2px 0;
    }

    .jump-btns {
        display: inline-block;
        margin-left: 6px;

        button {
            margin-left: 6px;
            padding: 4px 8px;
            background-color: #ced4da;
            color: #333;
            font-weight: bold;
        }
    }
}

#DeviceList {
    padding: 16px;
    background-color: #FFFFFF;

    .p-datatable-header {
        display: flex;
        justify-content: space-between;

        h5 {
            margin: auto 0;
        }

        .p-progress-spinner {
            width: 32px;
            height: 32px;
        }
    }
}

#DeviceDetails {
    h1 {
        text-align: center;
    }

    .p-datatable-header h2 {
        text-align: center;
        margin: 8px 0;
    }
}

#DeviceDetails,
#DeviceList {
    .action-column {
        width: 170px;

        button {
            display: inline-block;
            color: #F3F3F3;
            font-weight: bold;

            span {
                margin-right: 4px;
            }
        }
    }

    td.action-column button {
        margin-right: 8px;

        &:nth-of-type(1) {
            background-color: #8B8000;
        }

        &:nth-of-type(2) {
            background-color: #880088;
        }
    }

    th.action-column button {
        float: right;
        width: auto;
        background-color: #00CC95;
    }
}
.p-cascadeselect-panel li ul li ul li ul li ul{
    max-height: 500px;
    overflow-y: auto;
  }
#DeviceForm {
    
    form{
        width:fit-content;
        width:100%;
       padding-right: 20px;
    }
    form > div {
        margin-top: 20px;
    }
    .acaa-form > div {
     width: 100%;
     
    }
    .p-calendar {
        width: 97%;
    }
    .p-dropdown {
        width: 96%;
    }
    .acaa-form > div.full.with-button > span{
        width: 86%;
    }
    
    div.submit-footer{
        
        width: 50%;
        width: 100%;
        button{
            margin-left: 0;
        }
    }
    .full.with-button{
        width: 50%;
        width: 100%;
    }
}

#MasterCrud,
#Outage,
.issue-type-modal {
    .action-col {
        min-width: 195px;

        >* {
            float: right;
        }

        button {
            color: white;
            font-weight: bold;

            &.new {
                background-color: #00CC95;
            }

            &.edit {
                margin-right: 8px;
                background-color: #8B8000;
            }

            &.delete {
                background-color: #BB1111;
            }

            span {
                margin-right: 4px;
            }
        }
    }
}

#Batteries {
    padding: 16px;
    background-color: #FFFFFF;

    .p-datatable-header {
        >span.attention {
            margin-left: 24px;
        }
    }

    .header-cards {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            display: inline-block;
            width: 30%;
            max-width: 500px;
            margin: 10px;

            &:nth-of-type(1) {
                background-color: #d5e8d4;
            }

            &:nth-of-type(2) {
                background-color: #fff2cc;
            }

            &:nth-of-type(3) {
                background-color: #f8cecc;
            }
        }
    }

    .id-body {
        background-color: #FFFFFF;
        color: #146fd7;
        font-weight: 550;
        text-align: center;
        cursor: pointer
    }

    .status {
        text-align: center;

        span {
            padding: .25em .5rem;
            margin: 0 auto;
            border-radius: 2px;
            font-weight: bold;
            cursor: pointer;

            &.battery-low {
                background: #ffcdd2;
                color: #c63737;
            }

            &.battery-mid {
                background: #feedaf;
                color: #8a5340;
            }

            &.battery-high {
                background: #c8e6c9;
                color: #256029;
            }
        }
    }

    .battery-spinner {
        height: 291px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

#SensorDiagnostics {
    padding: 16px;
    background-color: #FFFFFF;

    .p-datatable-header {
        >span.attention {
            margin-left: 24px;
        }
    }

    .p-datatable .p-datatable-tbody>tr {
        height: 60px;
        /* Adjust the height as needed */
    }

    .card-hover {
        cursor: pointer;
    }

    .header-cards {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            display: inline-block;
            width: 30%;
            max-width: 500px;
            margin: 10px;

            &.operational {
                background-color: #d5e8d4;
            }

            &.malfunctioning {
                background-color: #f8cecc;
            }

            &.all {
                background-color: #fff2cc;
            }
        }
    }

    .id-body {
        background-color: #FFFFFF;
        color: #146fd7;
        font-weight: 550;
        text-align: center;
        cursor: pointer
    }

    .status {
        text-align: center;

        span {
            padding: .25em .5rem;
            margin: 0 auto;
            border-radius: 2px;
            font-weight: bold;
            cursor: pointer;

            &.battery-low {
                background: #ffcdd2;
                color: #c63737;
            }

            &.battery-mid {
                background: #feedaf;
                color: #8a5340;
            }

            &.battery-high {
                background: #c8e6c9;
                color: #256029;
            }
        }
    }

    .statusTable {
        text-align: center;

        span {
            padding: .25em .5rem;
            margin: 0 auto;
            border-radius: 2px;
            font-weight: bold;
            cursor: pointer;

            &.stagnate {
                background: #ffcdd2;
                color: #c63737;
            }

            &.to-stagnate {
                background: #feedaf;
                color: #8a5340;
            }

            &.normal {
                background: #c8e6c9;
                color: #256029;
            }
        }
    }

    .battery-spinner {
        height: 291px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}



#AztDiagnostics {
    padding: 16px;
    background-color: #FFFFFF;

    .p-datatable-header {
        >span.attention {
            margin-left: 24px;
        }
    }

    .p-datatable .p-datatable-tbody>tr {
        height: 60px;
        /* Adjust the height as needed */
    }

    .card-hover {
        cursor: pointer;
    }

    .header-cards {
        display: flex;
        justify-content: center;
        align-items: center;

        >div {
            display: inline-block;
            width: 30%;
            max-width: 500px;
            margin: 10px;

            &.normal {
                background-color: #d5e8d4;
            }

            &.stagnate {
                background-color: #f8cecc;
            }

            &.all {
                background-color: #fff2cc;
            }
        }
    }

    .id-body {
        background-color: #FFFFFF;
        color: #146fd7;
        font-weight: 550;
        text-align: center;
        cursor: pointer
    }

    .status {
        text-align: center;

        span {
            padding: .25em .5rem;
            margin: 0 auto;
            border-radius: 2px;
            font-weight: bold;
            cursor: pointer;

            &.battery-low {
                background: #ffcdd2;
                color: #c63737;
            }

            &.battery-mid {
                background: #feedaf;
                color: #8a5340;
            }

            &.battery-high {
                background: #c8e6c9;
                color: #256029;
            }
        }
    }

    .statusTable {
        text-align: center;

        span {
            padding: .25em .5rem;
            margin: 0 auto;
            border-radius: 2px;
            font-weight: bold;
            cursor: pointer;

            &.stagnate {
                background: #ffcdd2;
                color: #c63737;
            }

            &.to-stagnate {
                background: #feedaf;
                color: #8a5340;
            }

            &.normal {
                background: #c8e6c9;
                color: #256029;
            }
        }
    }

    .battery-spinner {
        height: 291px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}


#MasterCrud {
    >div {
        padding: 16px;
        background-color: #FFFFFF;
    }

    .p-toolbar {
        margin-bottom: 12px;
        padding-bottom: 6px;

        >div {
            display: inline-block;
            width: 100%;

            >div {
                display: inline-block;

                .p-selectbutton {
                    display: inline-block;
                    margin-right: 8px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

#LocationSelect {
    .overlay {
        position: absolute;
        top: 75px;
        right: 10px;
        width: 30vw;
        max-height: 85vh;
        padding: 20px 12px;
        border-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 1px 3px 0 rgba(0, 0, 0, 0.12);
        background-color: #FFFFFF;
        min-width: 600px;

        &.closed {
            height: 0;
            padding: 0;
            overflow: hidden;
        }

        &.loc-list {
            overflow-y: scroll;
        }

        &.lat-lng {
            h2 {
                margin-bottom: 24px;
            }

            p {
                margin-top: 20px;
            }

            >button {
                margin: 0 1%;
                width: 48%;

                &:nth-of-type(2) {
                    background-color: #997570;
                }
            }

            >div {
                display: inline-block;
                margin-right: 2%;
            }

            >div>div>button {
                margin: 0 1%;
                width: 48%;
            }
        }
    }

    tbody tr:hover {
        cursor: pointer;
    }
}

#Portal {
    .title {
        width: 200px;
    }

    .thumbnail {
        width: 220px;
    }

    td.thumbnail {
        height: 135px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .url div {
        display: inline-block;
        width: 250px;
        max-height: 115px;
        overflow: hidden;
    }

    .tags {
        width: 110px;
    }

    td.tags div {
        margin-bottom: 4px;
        padding-left: 4px;
        padding-top: 2px;

        &.line {
            border-top: 1px solid #DDD;
        }
    }

    .refresh {
        width: 120px;
    }

    .bool {
        width: 110px;

        i {
            font-size: 2.3rem;
        }
    }

    .timestamp {
        width: 150px;
    }

    .action-col {
        width: 110px;
    }

    th.action-col button {
        background: #228B22;
        color: #F5F5F5;

        span {
            margin-right: 4px;
        }
    }

    td.action-col button {
        margin-right: 4px;
        color: #F5F5F5;

        &.edit {
            background: #DAA520;
        }

        &.delete {
            background: #B22222;
        }

        span {
            font-size: 1.2rem;
        }
    }
}

#Heartbeat {
    position: relative;

    h1 i {
        margin-left: 16px;
        font-size: 2rem;
    }

    button.injector {
        position: absolute;
        top: 0;
        right: 16px;
        background-color: #CC5;
        color: #222;
        font-weight: bold;
    }

    >div.pipe {
        position: relative;
        display: inline-block;
        height: 350px;
    }

    >div.arrow {
        margin: 8px 0;
        vertical-align: top;

        div {
            display: flex;
            height: 100%;
            align-items: center;
        }

        i {
            font-size: 2rem;
            vertical-align: middle;
        }
    }

    >div.box {
        width: calc(33% - 1.5rem - 16px);
        margin: 8px;
        padding: 12px 16px;
        border: 4px solid #A33;
        border-radius: 4px;

        &.valid {
            border-color: #3A3;
        }

        .timestamp {
            position: relative;
            display: flex;
            height: 230px;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            text-align: center;
        }

        .processing {
            position: absolute;
            bottom: 12px;

            p {
                margin-bottom: 4px;
            }

            >div {
                display: inline-block;
                margin-left: 16px;
            }
        }
    }

    button.refresh {
        margin-top: 32px;
    }
}

#Outage {
    .p-datatable-header {
        display: flex;
        justify-content: space-between;

        .issue-list {
            background-color: #007bff;
        }

        .new-outage {
            border-color: #333;
            background-color: #00CC95;
        }
    }

    td.action-column {
        width: 205px;
    }

    button.outage-primary {
        color: #F5F5F5;
        font-weight: bold;
    }
}

#FlightCompare {
    .date-selectors {
        width: 100%;

        >div {
            display: inline-block;
            width: calc(50% - 50px);
            margin-bottom: 16px;
            vertical-align: top;

            span {
                width: 98%;
            }
        }

        button {
            vertical-align: top;
            margin-top: 18px;
        }

        i {
            vertical-align: top;
            margin-left: 8px;
            margin-top: 22px;
            font-size: 2rem;
            cursor: pointer;
        }

        .p-progress-spinner {
            width: 56px;
            height: 48px;
        }
    }

    .p-chart {
        max-width: 400px;
        max-width: 400px;
        margin: 24px auto;
    }

    .p-splitbutton-defaultbutton,
    .p-splitbutton-menubutton {
        background: #FFF;
        color: #123;
    }
}

.portal-modal {
    min-width: 300px;

    .p-dialog-content p {
        max-width: 50vw;
    }

    .p-dialog-content {
        overflow-x: hidden;
    }

    .p-dialog-footer button {
        display: inline-block;
        width: 48%;
        min-width: 120px;
        margin: 0 1%;
    }

    div.submit-footer {
        button {
            margin-left: 0;
        }
    }

    form>div {
        display: inline-block;
        margin-top: 28px;
        vertical-align: top;

        &.full {
            width: 100%;
        }

        &.half {
            width: 50%;
        }

        &.toggle-button {
            margin-top: 10px;

            .p-togglebutton.p-button {
                background-color: #007bff;
            }
        }

        input,
        textarea,
        .p-multiselect {
            width: calc(100% - 12px);
        }

        .p-checkbox+label {
            margin-left: 16px;
        }

        .orderlist-upload {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            background: #DDD;

            >p {
                font-weight: bold;
            }

            >* {
                margin: 6px;
            }
        }

        .p-orderlist {
            .p-orderlist-header {
                display: none;
            }

            li {
                position: relative;
                display: inline-block;
                max-width: 50%;
                vertical-align: top;

                img {
                    width: 100%;
                }

                i {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    padding: 4px;
                    font-size: 2rem;
                    background: rgba(255, 0, 0, 0.7);
                    color: #F5F5F5;

                    &:hover {
                        cursor: pointer;
                        color: #333;
                    }
                }

                div {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-weight: bold;
                    font-size: 2rem;
                    color: rgba(0, 0, 0, 0.5);
                    background: rgba(255, 255, 255, 0.3);
                }
            }

            &.length-3 li {
                max-width: 33%;
            }

            &.length-4 li {
                max-width: 25%;
            }
        }
    }
}

.portal-delete-modal {
    .p-dialog-content>div:first-of-type {
        margin-top: 24px;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
}

.issue-type-modal,
.issue-form-modal,
.outage-form-modal,
.outage-delete-modal {
    width: 50vw;
    min-width: 400px;

    .p-dialog-content {
        overflow: hidden;
    }
}

.outage-delete-modal,
.outagetype-delete-modal {
    .delete-confirm {
        width: 50%;
        margin-top: 12px;
        margin-left: 25%;
    }
}

.outage-form-modal form {
    width: 100%;

    .submit-footer {
        text-align: left;
    }
}